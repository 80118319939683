
import { createApp, createSSRApp, watch} from 'vue';
import SidebarMenu from "./SidebarMenu.vue";
import DarkModeBtn from "./DarkModeBtn.vue";
import DropdownMenu from "./DropdownMenu.vue";
import { useIsMobile, useIsSmallScreen } from "./Composables.index.ts";
import Spotlight from 'spotlight';
import { importUtils, $t } from 'o365-utils';


const { loadStyle } = importUtils;

loadStyle('spotlight/src/css/spotlight.css');

const isMobile = useIsMobile();
const createSidebarFactory = () => createApp(SidebarMenu);
let desktopSidebar = null;
let mobileSidebar = null;

if (!isMobile.value) {
    desktopSidebar = createSidebarFactory();

    desktopSidebar.mount("#menu");
} else {
    mobileSidebar = createSidebarFactory();
    mobileSidebar.mount("#mobile-menu");
    document.getElementById("sidebar").classList = "d-none sidebar";
    const menuToggler = document.getElementById("menu-toggler");
    delete menuToggler.dataset['bsToggle'];
    menuToggler.addEventListener('click', () => {
        let myOffcanvas = document.getElementById('offcanvasMenu');
        let bsOffcanvas = new bootstrap.Offcanvas(myOffcanvas);
        bsOffcanvas.show();
    });
}


watch(isMobile, () => {
    if (isMobile.value) {
        if (desktopSidebar) {
            desktopSidebar.unmount();
            desktopSidebar = null;
        }

        mobileSidebar = createSidebarFactory();
        mobileSidebar.mount("#mobile-menu");
        document.getElementById("sidebar").classList = "d-none sidebar";
        const menuToggler = document.getElementById("menu-toggler");
        delete menuToggler.dataset['bsToggle'];
        menuToggler.addEventListener('click', () => {
            let myOffcanvas = document.getElementById('offcanvasMenu');
            let bsOffcanvas = new bootstrap.Offcanvas(myOffcanvas);
            bsOffcanvas.show();
        });
    } else {
        if (mobileSidebar) {
            mobileSidebar.unmount();
            mobileSidebar = null;
        }

        desktopSidebar = createSidebarFactory();
        desktopSidebar.mount("#menu");

        document.getElementById("sidebar").classList = "sidebar";
    }
})

/* const dropdownMenu = createApp(DropdownMenu);
dropdownMenu.mount('#dropdown-menu'); */

/* const darkModeBtn = createSSRApp(DarkModeBtn);
darkModeBtn.mount('#partial-omega365-docs-header_app-docs-2-vue-toggle'); */



function loadSideBarAndLayout() {
    const isSmallScreen = useIsSmallScreen();

    // function updateLayout() {
    //     if (!isSmallScreen.value) {
    //         if (sidebarArticleNavEl.children.length > 0) {
    //             sidebarNavWrapper.classList = 'd-flex flex-column sticky-top pt-3';
    //         }
    //         articleNavWrapper.classList = 'd-none';
    //         document.getElementsByClassName('sidebar-right')[0].classList = 'sidebar-right d-block';
    //     } else {
    //         if (articleNavEl.children.length > 0) {
    //             articleNavWrapper.classList = 'd-flex flex-column';
    //         }
    //         sidebarNavWrapper.classList = 'd-none';
    //         document.getElementsByClassName('sidebar-right')[0].classList = 'sidebar-right d-none';
    //     }
    // }

    //updateLayout();
    //watch(isSmallScreen, updateLayout);
}


// window.addEventListener('load', () => {
//     //loadSideBarAndLayout();
    
//     // init Spotlight
//     document.querySelectorAll('img:not(.navbar-brand)').forEach(img => {
//         if(img.parentNode.tagName != 'A') {
//             img.classList.add('spotlight');
//         }
        
//     });

    
// });
