<script setup lang="ts">
import { API } from 'o365-modules';
import NestedList, { NestedListNode } from "./Components.NestedList.vue";
import { onMounted, ref, Ref } from "vue";
import MenuSearch from "./Components.MenuSearch.vue";

let nodes: Ref<NestedListNode> = ref([]);

onMounted(async () => {
    // @ts-ignore
    // Server side generated menu items
    
    let data: any = ssgManualsPayload;
    let nodeValues = data.map((item: any) => mapToNestedListNode(item, 1));

    nodes.value = nodeValues;
    
});

function mapToNestedListNode(item: any, level: number, getParent?: () => NestedListNode): NestedListNode {
    
    let key = 'Children' in item ? 'Children' : 'Sections' in item ? 'Sections' : null;
    let children: any[] = [];
    let node: Partial<NestedListNode> = {};

    function getParentNode() {
        return this;
    }

    if (key == 'Children') {
        children = [...Object.values(item.Children).map(x => mapToNestedListNode(x, level + 1, getParentNode.bind(node))), ...item.ContentItems.map(x => mapToNestedListNode(x, level + 1, getParentNode.bind(node)))];
    } else if (key == 'Sections') {
        children = Object.values(item.Sections)
            .sort(((a: any , b: any) => a.SortOrder - b.SortOrder))
            .map(x => mapToNestedListNode(x, level + 1, getParentNode.bind(node)))
    }

    let labelKey = !key ? 'Title' : key == 'Sections' ? 'ManualName' : 'Name';
    let isSectionItem = 'ContentItemId' in item;
    let isContentItem = 'SectionItemId' in item;
    let value = isSectionItem ? item['ContentItemId'] && item['Id'] : isContentItem ? item['SectionItemId'] : null;
    value = !!value ? isContentItem ? `MSI${value}` : `MS${value}` : null;
    
    node.ContentItemId = isSectionItem ? item['ContentItemId'] : item['Id'];
    node.label = item[labelKey];
    node.value = value;
    node.children = children;
    node.getParent = getParent;
    node.id = item['Id'];
    node.type = item['Type'];
    node.area = item["AreaId"];
    node.manual = item["ManualId"];

    node.expandLevel = item["ExpandLevel"];
    node.level = level;

    if (item['ContentItemName']) {
        node.name = item['ContentItemName'];
    }

    // @ts-ignore
    return node;
}

async function handleNavClick(node) {
    
    const elem = node.getElement();
    
    if(node.value != null && node.value != 0 && node.ContentItemId && node.ContentItemId != 0) {
        
         document.body.style.cursor = 'wait';
        let lastActive = document.querySelector('.menu-item.active');
        if (lastActive) {
            lastActive.classList.remove('active');
        }
        elem.classList.add('active');

        const body = {
                Id: node.ContentItemId,
                Mode: articleMode,
                Language: articleLanguage,
                Title: node.label,
                ShowNavButtons: showNavButtons,
                ItemId: node.value
            }
        
        const res = await API.requestPost("/docs?handler=GetManualItem",JSON.stringify(body))
            .catch((err) => {
                console.error(err);
                return;
            });
        
        if(res && res.ok) {
            // @ts-ignore
            window.deadend = false;
            const result = await res.text();
            const re = /<script\b[^>]*>([\s\S]*?)<\/script>/gm;
            let script = '';
            let match;
            while (match = re.exec(result)) {
                
                script += match[0];
            }
            const html = result; //.replace(script,'');
            // @ts-ignore
            document.getElementById('docs-article').innerHTML = html;
            document.body.style.cursor = 'default';
            let url = new URL(location.href);
            location.hash = '';
            url.searchParams.set('ID',node.value);
            
            window.history.pushState({"html":html}, null, `?${url.searchParams.toString()}`);
            
             let myOffcanvas = document.getElementById('offcanvasMenu');
            let bsOffcanvas = bootstrap.Offcanvas.getInstance(myOffcanvas);
            if(myOffcanvas && bsOffcanvas && bsOffcanvas._isShown) {
                
                bsOffcanvas.hide();
            }
            
            setupArticleEventListeners();
            //loadArticleNav();
            initSpotlightForUpdatedDocument();
        } else if (res){
            document.getElementById('docs-article').innerHTML = `"<div>${res.statusText}</div>"`;
        }
    }
   

}



</script>

<template>
    <div class="d-flex position-relative mb-10">
        <MenuSearch />
    </div>
    <div class="d-flex flex-column flex-grow-1" style="margin-top: 50px">
        <NestedList v-for="node in nodes" :node="node" @onClick="handleNavClick($event)" />
    </div>
</template>

<style>
#app {
    display: flex;
    flex-grow: 1;
}

.container {
    flex-grow: 1;
}
</style>