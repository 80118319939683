<script setup lang="ts">
import { onMounted, ref, Ref, watch, computed } from 'vue';

const isActive = ref(false);
const isHovering = ref(false);
const showSearch = ref(false);
const searchVal = ref("");
let searchItems: SearchItem[] = [];
let searchResults: Ref<SearchItem[]> = ref([]);
const areaName = ref("");
const areaId = ref(null);
const filterPlaceHolder = ref("Filter by title");

function getClasses() {
    return {
        'search-active': isActive.value
    }
}

watch(searchVal, () => {
    showSearch.value = true;
    if (searchItems.length == 0) initSearchsItems();
    searchResults.value = search(searchVal.value);
});

function search(q: string) {
    return searchItems.filter(x => x.name.toLowerCase().includes(q.toLowerCase())).slice(0, 5);
}

function traverseGetContentItemsBySection(section: any, cb: (items: SearchItem[]) => void, manualName: string) {
    let path = section.PathName.replace('/', ' > ');
    let items = section.ContentItems.map(x => contentItemToSearchItem(x, `${manualName} > ${path}`));

    if (section.ContentItemId) {
        items.push(sectionToSearchItem(section, `${manualName} > ${path}`));
    }
 
    const children = Object.values(section.Children);

    if (children.length > 0) {
        children.forEach(x => traverseGetContentItemsBySection(x, cb, manualName));
    }

    cb(items);
}

function contentItemToSearchItem(contentItem: any, path: string) {
    return {
        name: contentItem.Title,
        value: 'MSI' + contentItem.SectionItemId,
        path: `${path}`
    }
}

function sectionToSearchItem(section: any, path: string) {
    return {
        name: section.Name,
        value: 'MS' + section.Id,
        path
    }
}

function callbackFunc(items: SearchItem[]) {
    searchItems.push(...items);
}

function initSearchsItems() {
    // @ts-ignore
    for (let i = 0; i < ssgManualsPayload.length; i++) {
        // @ts-ignore
        let sections = Object.values(ssgManualsPayload[i].Sections);
        // @ts-ignore
        sections.forEach(x => traverseGetContentItemsBySection(x, callbackFunc, ssgManualsPayload[i].ManualName));
    }
}

onMounted(() => {
    // @ts-ignore
    areaName.value = ssgAreaInfo.Area;
    // @ts-ignore
    areaId.value = ssgAreaInfo.AreaId;
    window.addEventListener('click', () => {
        if (!isHovering.value) {
            showSearch.value = false;
        }
    });

    let url = new URL(window.location.href);
    const lang = url.searchParams.get("lang");
    if(lang && lang == "nb-no") {
        filterPlaceHolder.value = "Filtrer på tittel";
    }
});

let navigateTo = (href: string) => window.location.href = href;

function handleNavClick(val: string | null) {
    let url = new URL(window.location.href);
    
    if (val?.includes('MS')) {
        url.searchParams.set('ID', val);

        if (isDevelopment) {
            navigateTo(`${url.origin}/nt/docs?${url.searchParams.toString()}`);
        } else {
            // Dev environment-check may be omitted at a later point. Currently has no effect here.
            navigateTo(`${url.origin}/nt/docs?${url.searchParams.toString()}`);
        }
        //navigateTo('/nt/docs?' + url.searchParams.toString());
    }
}

function handleSearch() {
        let url = new URL(window.location.href);
        
        const lang = url.searchParams.get('lang');
        let navurl =`/nt/docs-search?Area-ID=${areaId.value} &Search=${encodeURI(searchVal.value)}`;
        if(lang != null) {
            navurl += `&lang=${lang}`;
        }

        navigateTo(navurl);

        // if (isDevelopment) {
            // navigateTo(`${url.origin}/nt/docs-search?${url.searchParams.toString()}`);
        // } else {
            // navigateTo(`${url.origin}/nt/docs-search?${url.searchParams.toString()}`);
            // //navigateTo(`/nt/docs-search?Search=${encodeURI(searchVal.value)}&Area-ID=${areaId.value}`);
        // }
    // } else {
        // navigateTo(`${url.origin}/nt/docs-search?${url.searchParams.toString()}`);
        // //handleNavClick(searchResults.value[0].value);
    // }
}
</script>

<template>
    <div class="search d-flex flex-grow-1 mb-3 mx-3" :class="getClasses()" @mouseover="isHovering = true" @mouseout="isHovering = false">
        <input ref="inputRef" @keyup.enter="handleSearch" v-model="searchVal" @blur="isActive = false" @focus="isActive = true" type="text" class="form-control" :placeholder="filterPlaceHolder">
        <div class="search-results" v-if="searchVal && showSearch">
            <ul>
                <div v-if="searchResults.length == 0">
                    <a :href="`/nt/docs-search?Search=${encodeURI(searchVal)}&Area-ID=${areaId}`"><li>Search for '{{ searchVal }}' in {{ areaName }}</li></a>
                    <a :href="`/nt/docs-search?Search=${encodeURI(searchVal)}`"><li>Search for '{{ searchVal }}' in all documentation</li></a>
                </div>
                <li v-for="res in searchResults" class="d-flex flex-column search-item" @click="handleNavClick(res.value)">
                    <h3>{{ res.name }}</h3>
                    <span>{{ res.path }}</span>
                </li>
            </ul>
        </div>
    </div>
</template>

<script lang="ts">
interface SearchItem {
    name: string;
    path: string;
    value: string;
}
</script>

<style scoped>
.search-item h3 {
    font-size: 16px !important;
    font-weight: 400;
    margin: 0 !important;
}

.search-item span {
    font-size: 12px;
    color: #a8a8a8;
}
.search {
    position: absolute;
    left: 0;
    right: 0;
    background-color: var(--bs-body-bg);
    flex-direction: column;
    border-radius: 4px;
    overflow: hidden;
    border: 1px solid #d8d8d8;
    transition: 200ms cubic-bezier(0.075, 0.82, 0.165, 1);
    z-index: 1000;
}

.search-results ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.search-results ul li {
    padding: 4px 12px;
    border: 3px dotted transparent;
}

.search-results ul li:hover {
    background-color: var(--bs-primary) !important;
    color: white !important;
    cursor: pointer;
}

.search-results ul li:hover a {
    color: white !important;
}

.search-results ul li a:hover {
    color: white !important;
}

.search-results ul li:hover h3 {
    color: white !important;
}

[data-bs-theme="dark"] li:hover {
    border: 3px dotted white;
}

.search .form-control {
    /*background-color: transparent;*/
    border-radius: 0 !important;
    border: 0 !important;
}

.search .form-control:focus {
    outline: none !important;
    -webkit-box-shadow: none;
    box-shadow: none;
}
</style>